<template>
  <div class="dynamic-sum">
    <h3>Суммы</h3>

    <a-table
      bordered
      :columns="columns"
      :data-source="data"
      :scroll="{ x: 2500 }"
      :pagination="{ defaultPageSize: 12, hideOnSinglePage: true }"
    ></a-table>

    <Diagrams :list="diagramsList" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import Diagrams from "@/components/diagrams/Diagrams"

export default {
  name: "DynamicSum",
  components: {
    Diagrams,
  },
  data() {
    return {
      diagramsList: [
        {
          link: require("../../../assets/reports/dynamic/sum/diagram1.png"),
        },
        {
          link: require("../../../assets/reports/dynamic/sum/diagram2.png"),
        },
        {
          link: require("../../../assets/reports/dynamic/sum/diagram3.png"),
        },
        {
          link: require("../../../assets/reports/dynamic/sum/diagram4.png"),
        },
        {
          link: require("../../../assets/reports/dynamic/sum/diagram5.png"),
        },
      ],
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: mapGetters({
    columns: "reportDynamic/sumColumns",
    data: "reportDynamic/sumData",
  }),
  methods: mapActions({
    fetchData: "reportDynamic/fetchSumData",
  }),
}
</script>

<style lang="scss" scoped></style>
