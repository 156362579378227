<template>
  <div class="reports-dynamic-page">
    <h3>Динамика</h3>
    <Dynamic />
  </div>
</template>

<script>
import Dynamic from "@/components/reports/Dynamic"

export default {
  name: "ReportsDynamic",
  components: {
    Dynamic,
  },
}
</script>

<style lang="scss" scoped></style>
