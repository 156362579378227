<template>
  <div class="dynamic">
    <Filters class="content-container" :hide-dealer="hideDealer" />
    <DynamicLine class="element content-container" v-if="isLineShow" />
    <DynamicSum class="element" v-if="isSumShow" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import Filters from "@/components/reports/dynamic/Filters"
import DynamicLine from "@/components/reports/dynamic/DynamicLine"
import DynamicSum from "@/components/reports/dynamic/DynamicSum"

export default {
  name: "Dynamic",
  props: {
    hideDealer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Filters,
    DynamicLine,
    DynamicSum,
  },
  computed: mapGetters({
    isLineShow: "reportDynamic/isLineShow",
    isSumShow: "reportDynamic/isSumShow",
  }),
}
</script>

<style lang="scss" scoped>
.period {
  .element {
    margin-bottom: 80px;
  }
}
</style>
